document.addEventListener('DOMContentLoaded', () => {
	const header = document.querySelector('.header');
	const hamburger = document.querySelector('.header .hamburger');
	const mobileNav = document.querySelector('.mobileNav');
	hamburger.addEventListener('click', () => {
		hamburger.classList.toggle('hamburger--opened');
		header.classList.toggle('header--open');
		hamburger.setAttribute(
			'aria-expanded',
			hamburger.classList.contains('hamburger--opened')
		);
		mobileNav.classList.toggle('open');
	});
	let vh = window.innerHeight * 0.01;

	if (document.querySelector('.mobileNav__list .menu-item-has-children')) {
		let subMenus = document.querySelectorAll(
			'.mobileNav__list .menu-item-has-children'
		);
		subMenus.forEach((subMenu) => {
			let link = subMenu.querySelector('a');
			let span = subMenu.querySelector('span');
			let ul = subMenu.querySelector('ul');
			link.addEventListener('click', (e) => {
				if (subMenu.querySelector('span')) {
					e.preventDefault();
					ul.classList.toggle('sub-menu--open');
					span.classList.toggle('open');
				}
			});
		});
	}

	document.documentElement.style.setProperty('--vh', `${vh}px`);

	window.addEventListener('scroll', () => {
		document.body.scrollTop > 0 || document.documentElement.scrollTop > 0
			? header.classList.add('header--scrolled')
			: header.classList.remove('header--scrolled');
	});

	if (document.querySelector('.intro__slider')) {
		var clientSwiper = new Swiper('.intro__slider.swiper', {
			slidesPerView: 1,
			spaceBetween: 0,
			loop: 1,
			effect: 'fade',
			speed: 500,
		});
	}

	const getSectionParent = (node) => {
		while (true) {
			if (node.parentNode.tagName == 'SECTION') {
				return node.parentNode;
			}
			if (node.parentNode.tagName == 'BODY') {
				return node;
			}
			node = node.parentNode;
		}
	};

	gsap.registerPlugin(ScrollTrigger);

	const fadeups = document.querySelectorAll('.fade-up');

	fadeups.forEach((fadeup) => {
		gsap
			.timeline({
				scrollTrigger: {
					trigger: getSectionParent(fadeup),
					start: 'top 75%',
				},
			})
			.fromTo(
				fadeup,
				{ autoAlpha: 0, y: 50 },
				{ autoAlpha: 1, y: 0, delay: 0.1, duration: 0.8 }
			);
	});
});
